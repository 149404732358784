<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>User Payment Method</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">User Payment Method</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card" id="UserPaymentMethod">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <form @submit.prevent="handleSubmission()" method="POST" novalidate>
            <div class="card-header">
              <h3 class="card-title" v-if="!is_edit">Create</h3>
              <h3 class="card-title" v-if="is_edit">Edit</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'UserPaymentMethodList'}"><i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <div class="form-group required">
                    <label class="control-label">User-Id</label>
                    <input type="text" id="user_id" name="user_id" v-bind:class="{'has-error' : errors.has('user_id')}" v-validate="'required'" v-model="value.user_id" class="form-control user_id" placeholder="User ID">
                    <div class="help text-danger" v-show="errors.has('user_id')">
                      {{ errors.first("user_id") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Account-Id</label>
                    <input type="text" id="account_id" name="account_id" v-bind:class="{'has-error' : errors.has('account_id')}" v-validate="'required'" v-model="value.account_id" class="form-control account_id" placeholder="Account ID">
                    <div class="help text-danger" v-show="errors.has('account_id')">
                      {{ errors.first("account_id") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Gateway-Id</label>
                    <input type="text" id="gateway_id" name="gateway_id" v-bind:class="{'has-error' : errors.has('gateway_id')}" v-validate="'required'" v-model="value.gateway_id" class="form-control gateway_id" placeholder="Gateway-Id">
                    <div class="help text-danger" v-show="errors.has('gateway_id')">
                      {{ errors.first("gateway_id") }}
                    </div>
                  </div>
                  <div class="offset-0 col-12">
                    <h4 class="site-heading my-3"><span>Gateway Parameters</span></h4>
                    <div class="form-row align-items-end" v-for="(key_val, i) in value.gateway_params_converted" :key="i">
                      <div class=" col-md-10">
                        <div class="row">
                          <div class="col">
                            <div class="form-group col-md-12">
                              <label class="control-label">Key</label>
                              <input type="text" name="key_val.key" v-model="key_val.key" class="form-control key_val.key" placeholder="Key">
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group col-md-12">
                              <label class="control-label">Value</label>
                              <input type="text" name="key_val.value" v-model="key_val.value" class="form-control key_val.value" placeholder="Value">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-2">
                        <button type="button" class="btn btn-danger btn-sm float-right" @click="delete_key_value(i)"><i class="fa fa-trash"></i> Remove</button>
                      </div>
                    </div>
                    <button type="button" @click="add_key_value()" class="btn btn-success btn-sm pb-2"><i class="fa fa-plus"></i> New</button>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Priority</label>
                    <input type="text" id="priority" name="priority" v-bind:class="{'has-error' : errors.has('priority')}" v-validate="'required'" v-model="value.priority" class="form-control priority" placeholder="Priority">
                    <div class="help text-danger" v-show="errors.has('priority')">
                      {{ errors.first("priority") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Status</label>
                    <select class="form-control" style="width: 100%;" v-bind:class="{'has-error' : errors.has('status')}" v-validate="'required'" name="status" v-model="value.status" id="status">
                      <option :value="1">Active</option>
                      <option :value="0">Inactive</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('status')">
                      {{ errors.first("status") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Application-Id</label>
                    <select id="categories" v-model="value.app_id" class="form-control">
                      <option v-for="application in allApplications" :value="application.app_id" :key="application.app_id">{{ application.name }}</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('app_id')">
                      {{ errors.first("app_id") }}
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
              <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CBSUserManagementApi } from "@/services/api";

export default {
  name: "UserPaymentMethodForm",
  components: {
  },
  computed: {
    ...mapGetters(["profile"])
  },
  data: () => ({
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},
    value: {
      gateway_params: {},
      gateway_params_converted: []
    },
    allApplications: [],
    pre: {}
  }),
  methods: {
    handleSubmission () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.is_loading = true
          //key value convert
          this.value.gateway_params = {}
          this.value.gateway_params_converted.forEach((value, key) => {
            this.value.gateway_params[value.key] = value.value
          })

          if (this.is_edit) {
            this.value.updated_by = this.profile.id
            CBSUserManagementApi.updateUserPaymentMethod(this.params.id, this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: "UserPaymentMethodList" });
                  this.$toaster.success(response.message);
                } else {
                  this.$toaster.error(response.message);
                }
              })
              .catch((error) => {
                this.$toaster.error(error.data);
              })
          } else {
            this.value.organization_ref_id = this.profile.organization_ref_id
            this.value.user_ref_id = this.profile.user_ref_id
            this.value.role_ref_id = this.profile.role_ref_id
            this.value.created_by = this.profile.id
            CBSUserManagementApi.createUserPaymentMethod(this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: 'UserPaymentMethodList' });
                  this.$toaster.success(response.message);
                } else {
                  this.$toaster.error(response.message);
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.data);
              })
          }
        }
      });
    },
    getDetail: function (id) {
      CBSUserManagementApi.showUserPaymentMethod(id)
        .then((item) => {
          this.value = item

          let obj = []
          _.forEach(this.value.gateway_params, function (value, key) {
            obj.push({ 'key': key, 'value': value })
          })
          this.$set(this.value, 'gateway_params_converted', obj)
          this.$delete(this.value, "gateway_params")
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    getApplications: function () {
      CBSUserManagementApi.getAllApplications().then((response) => {
        this.allApplications = response;
      });
    },
    add_key_value: function () {
      this.value.gateway_params_converted.push({});
    },
    delete_key_value: function (index) {
      this.value.gateway_params_converted.splice(index, 1)
    },
    async initialize () {
      this.is_loading = true;
      this.params = this.$route.params

      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true
      }
      if (this.is_edit) {
        await this.getDetail(this.params.id)
      }
      await this.getApplications()
      this.is_loading = false


    }
  },
  mounted () {
    this.initialize()
  }
}
</script>

